// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-post-blog-jsx": () => import("./../../../src/templates/post-blog.jsx" /* webpackChunkName: "component---src-templates-post-blog-jsx" */),
  "component---src-templates-post-country-jsx": () => import("./../../../src/templates/post-country.jsx" /* webpackChunkName: "component---src-templates-post-country-jsx" */),
  "component---src-templates-post-device-jsx": () => import("./../../../src/templates/post-device.jsx" /* webpackChunkName: "component---src-templates-post-device-jsx" */),
  "component---src-templates-post-operator-jsx": () => import("./../../../src/templates/post-operator.jsx" /* webpackChunkName: "component---src-templates-post-operator-jsx" */),
  "component---src-templates-single-page-jsx": () => import("./../../../src/templates/single-page.jsx" /* webpackChunkName: "component---src-templates-single-page-jsx" */)
}

