// eslint-disable-next-line import/no-extraneous-dependencies
import React from 'react'

export const IconStarOn = () => (
  <svg width="100%" height="100%" viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="rate" transform="translate(-1.000000, 0.000000)" fill="#FFC108" fill-rule="nonzero">
        <path d="M411.272,512.01601 C409.068874,512.01601 406.850112,511.562144 404.772,510.638771 L256.99088,444.844851 L109.20976,510.638771 C103.741024,513.064582 97.366016,512.21943 92.70976,508.494666 C88.037888,504.75425 85.8504,498.713226 87.022272,492.844362 L119.615872,329.564682 L5.678272,215.427082 C1.5064,211.248458 -0.024864,205.113514 1.678272,199.448106 C3.3813984,193.798346 8.068896,189.54145 13.850144,188.383338 L177.162784,155.658538 L242.365984,8.7020576 C247.52224,-2.8791584 266.459744,-2.8791584 271.616,8.7020576 L336.8192,155.658538 L500.13184,188.383338 C505.913088,189.541466 510.600576,193.798346 512.303712,199.448106 C514.006838,205.113514 512.475587,211.248458 508.303712,215.427082 L394.366112,329.564682 L426.959712,492.844362 C428.131587,498.713226 425.944086,504.75425 421.272224,508.494666 C418.381597,510.810922 414.83472,512.01601 411.272224,512.01601 L411.272,512.01601 Z" id="Shape"></path>
      </g>
    </g>
  </svg>
)
