// eslint-disable-next-line import/no-extraneous-dependencies
import React from 'react'

export const IconSelectBox = () => (
  <svg width="9" height="9" viewBox="0 0 9 9" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-1087.000000, -343.000000)" fill="#EE4256" fill-rule="nonzero">
        <g transform="translate(1087.000000, 343.000000)">
          <path
            d="M8.83293656,0.164906328 C8.72168408,0.0549383729 8.58980647,0 8.43742689,0 L0.562542328,0 C0.410101176,0 0.278315923,0.0549383729 0.166971084,0.164906328 C0.0556262442,0.274995896 0,0.405151499 0,0.555616362 C0,0.706050822 0.0556262442,0.836206425 0.166971084,0.946204783 L4.10442876,4.83497206 C4.21589673,4.94494001 4.34768198,5 4.5,5 C4.65231802,5 4.7842264,4.94494001 4.89547889,4.83497206 L8.83293656,0.94617438 C8.94415827,0.836206425 9,0.706050822 9,0.555585959 C9,0.405151499 8.94415827,0.274995896 8.83293656,0.164906328 Z"></path>
        </g>
      </g>
    </g>
  </svg>
)
