module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"config/typography.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-145159380-4","head":true,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager-timeout/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-K8WC5CG","timeout":4000,"dataLayerName":"dataLayer"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Cheap eSIM data and voice plans online | Search, compare prices and buy | eSIM.Ninja","short_name":"Cheap eSIM data and voice plans online | Search, compare prices and buy | eSIM.Ninja","description":"Save on voice and mobile data plans while traveling the world. Hundreds eSIM offers from trusted operators. Instant online activation.","start_url":"/","background_color":"#1C032E","theme_color":"#2A0643","display":"standalone","icon":"src/icon.png","include_favicon":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"998f04c8d88966ffaa250dceba2ebd4a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-better-jss/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
