// eslint-disable-next-line import/no-extraneous-dependencies
import React from 'react'

export const IconClock = () => (
  <svg width="100%" height="100%" viewBox="0 0 104 102" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1057.000000, -1707.000000)" fill="#60003F"
         fillRule="nonzero">
        <g transform="translate(2.000000, 1222.000000)">
          <g>
            <g>
              <g transform="translate(1055.000000, 485.364179)">
                <path
                  d="M52,0 C23.3272128,0 0,22.7353283 0,50.680597 C0,78.6258657 23.3272128,101.361194 52,101.361194 C80.6727872,101.361194 104,78.6258657 104,50.680597 C104,22.7353283 80.6727872,0 52,0 Z M52,90.5772226 C29.4284891,90.5772226 11.0636476,72.6804412 11.0636476,50.680597 C11.0636476,28.6807528 29.4284891,10.7839714 52,10.7839714 C74.5715109,10.7839714 92.9363524,28.6807528 92.9363524,50.680597 C92.9363524,72.6804412 74.5715109,90.5772226 52,90.5772226 Z"
                ></path>
                <path
                  d="M79.6254434,49.1454004 L55.7491133,49.1454004 L55.7491133,21.7220759 C55.7491133,19.4143378 53.7904055,17.5432836 51.3745566,17.5432836 C48.9587077,17.5432836 47,19.4143378 47,21.7220759 L47,53.3241928 C47,55.6319308 48.9587077,57.5029851 51.3745566,57.5029851 L79.6254434,57.5029851 C82.0412923,57.5029851 84,55.6319308 84,53.3241928 C84,51.0164547 82.0412923,49.1454004 79.6254434,49.1454004 Z"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
