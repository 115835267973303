/* eslint no-unused-expressions: 0 */
/* eslint react/destructuring-assignment: 0 */

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, Link } from 'gatsby'
import '../styles/fonts.css'
import { Global, css } from '@emotion/core'
import styled from '@emotion/styled'
import { ThemeProvider } from 'emotion-theming'
import '@reach/skip-nav/styles.css'
// eslint-disable-next-line import/no-cycle
import Footer from './Footer'
// import SkipNavLink from './SkipNavLink'
import { theme, reset } from '../styles'
import i18n from '../../config/i18n'

const globalStyle = css`
  ${reset}
  h1, h2, h3, h4, h5, h6 {
    color: ${theme.colors.black};
  }
  h2 {
    font-size: 2rem;
    @media (max-width: ${theme.breakpoints.s}) {
      font-size: 1.5rem;
    }
  }
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body {
    color: ${theme.colors.greyDarker};
    background-color: ${theme.colors.bg};
    overflow: hidden;
  }
  ::selection {
    color: ${theme.colors.bg};
    background-color: ${theme.colors.primary};
  }
  a {
    color: ${theme.colors.mainColorLight};
    transition: all 0.4s ease-in-out;
    text-decoration: none;
    font-weight: 700;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
  @media (max-width: ${theme.breakpoints.m}) {
    html {
      font-size: 16px !important;
    }
  }
`

const MainWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

const LocaleContext = React.createContext()

const getFooterLinks = (footer, locale = 'en-us') => {
  const edge = footer.edges.filter(({ node: { lang } }) => lang === locale)[0]
  if (edge) {
    return edge.node.data.footer_links.map(({ link: { document } }) => {
      const {
        uid,
        data: { name },
      } = document[0]
      return { path: uid, label: name }
    })
  }
  return []
}

const Layout = ({ children, pageContext }) => {
  const { locale, name } = pageContext
  const { footer } = useStaticQuery(query)
  const footerLinks = getFooterLinks(footer, locale)
  const noFooter = name && name.match(/404/)

  return (
    <LocaleContext.Provider value={{ locale, i18n }}>
      <ThemeProvider theme={theme}>
        <MainWrapper>
          <Global styles={globalStyle} />
          {children}
          {!noFooter && <Footer links={footerLinks} />}
        </MainWrapper>
      </ThemeProvider>
    </LocaleContext.Provider>
  )
}

export { LocaleContext, Layout }

const query = graphql`
  query LayoutQuery {
    footer: allPrismicBlockFooter {
      edges {
        node {
          uid
          lang
          data {
            footer_links {
              link {
                document {
                  uid
                  data {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string,
  }),
}

Layout.defaultProps = {
  pageContext: {
    locale: 'en-us',
  },
}
