module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'Cheap eSIM data and voice plans online | Search, compare prices and buy | eSIM.Ninja', // Navigation and Site Title
  titleAlt: 'Cheap eSIM data and voice plans online | Search, compare prices and buy | eSIM.Ninja', // Title for JSONLD
  description: 'Save on voice and mobile data plans while traveling the world. Hundreds eSIM offers from trusted operators. Instant online activation.',
  headline: 'Plans comparison', // Headline for schema.org JSONLD
  url: 'https://esim.ninja', // Domain of your site. No trailing slash!
  logo: '/logos/esim-ninja.png', // Used for SEO
  ogLanguage: 'en_US', // Facebook Language

  // JSONLD / Manifest
  icon: 'src/icon.png', // Used for manifest icons generation
  shortName: 'esim', // shortname for manifest. MUST be shorter than 12 characters
  author: 'eSIM.Ninja', // Author for schemaORGJSONLD
  themeColor: '#2A0643',
  backgroundColor: '#1C032E',

  twitter: '@esim.ninja', // Twitter Username
  facebook: 'eSIM.Ninja', // Facebook Site Name

  skipNavId: 'reach-skip-nav', // ID for the "Skip to content" a11y feature
}
