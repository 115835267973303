// eslint-disable-next-line import/no-extraneous-dependencies
import React from 'react'

export const IconLogo = props => (
  <svg width="102px" height="25px" viewBox="0 0 102 25" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-24.000000, -23.000000)">
        <g transform="translate(24.000000, 23.000000)">
          <text
            fontFamily="Nunito, Arial, sans-serif"
            fontSize="24"
            fontWeight="800"
            linespacing="24"
            fill={props.dark ? '#424242' : '#FFF'}
          >
            <tspan x="0" y="24">
              eSIM.
            </tspan>
          </text>
          <g transform="translate(68.000000, 0.000000)">
            <path
              d="M26.0555556,24.0193887 C25.6222222,23.9304998 23.2777778,23.2860553 23.2333333,23.252722 C23.2111111,23.2304998 23.3,23.0749442 23.4333333,22.8971664 C23.8888889,22.252722 24.5555556,20.6971664 24.7333333,19.8749442 C25.0333333,18.4416109 24.7222222,16.9416109 24.0111111,16.4416109 C23.6333333,16.1749442 22.0222222,15.6638331 20,15.1749442 C14.4333333,13.8193887 11.8888889,13.7638331 7.5,14.8749442 C5.52222222,15.3749442 4.94444444,15.6082775 4.34444444,16.152722 C3.55555556,16.8638331 3.51111111,17.4304998 4.02222222,19.9971664 C4.21111111,20.9638331 4.34444444,21.7860553 4.32222222,21.8082775 C4.3,21.8304998 3.51111111,22.0304998 2.56666667,22.2304998 L0.866666667,22.6193887 L0.711111111,22.252722 C0.633333333,22.052722 0.422222222,21.252722 0.255555556,20.4638331 C1.11022302e-15,19.2416109 -0.0555555556,15.252722 0.255555556,14.6638331 C0.7,13.8304998 1.48888889,13.0971664 2.45555556,12.6304998 C4.6,11.5971664 8.98888889,10.1304998 11.5888889,9.58605533 C13.2555556,9.24161088 15.3777778,9.11938866 16.6555556,9.30827755 C19.2333333,9.68605533 22.9444444,11.152722 26.0555556,13.0304998 C28.1888889,14.3304998 28.7777778,15.1082775 28.7777778,16.7082775 C28.7777778,17.7638331 28.5444444,18.7971664 27.7111111,21.4304998 C27.3777778,22.4971664 27.0555556,23.6749442 27,23.8749442 C26.9350736,24.0697235 26.7117981,24.2835386 26.5555556,24.3193887 C26.4176757,24.3510253 26.352452,24.0871019 26.0555556,24.0193887 Z"
              fill={props.dark ? '#424242' : '#FFF'}
              fillRule="nonzero"
              transform="translate(14.410163, 16.770805) scale(1, -1) translate(-14.410163, -16.770805) "
            ></path>
            <path
              d="M13.4452738,7.85980796 C10.6008293,7.33758573 8.4563849,6.63758573 6.23416268,5.52647462 C4.84527379,4.83758573 4.43416268,4.55980796 3.72305157,3.9042524 C2.76749601,3.0042524 2.66749601,2.84869684 2.1563849,1.34869684 C1.87860712,0.504252401 1.66666667,-2.84217094e-14 1.77777778,-2.84217094e-14 C1.86666667,-2.84217094e-14 2.94527379,0.437585734 3.96749601,0.648696845 C6.80082935,1.24869684 10.8897182,1.99314129 12.5008293,2.2042524 C15.0786071,2.54869684 18.3452738,2.18203018 24.0341627,0.926474623 C25.1230516,0.682030178 26.0341627,0.482030178 26.0563849,0.482030178 C26.0786071,0.482030178 25.8786071,1.11536351 25.6008293,1.9042524 C24.7119405,4.41536351 24.1452738,5.1042524 22.1897182,6.07091907 C20.4008293,6.94869684 19.2230516,7.31536351 17.3563849,7.59314129 C15.7230516,7.82647462 13.9341627,7.94869684 13.4452738,7.85980796 Z"
              fill={props.dark ? '#424242' : '#FFF'}
              fillRule="nonzero"
              transform="translate(13.902861, 3.944444) scale(1, -1) translate(-13.902861, -3.944444) "
            ></path>
            <circle
              fill={props.dark ? '#424242' : '#FFF'}
              cx="8.55555556"
              cy="15.1111111"
              r="2.66666667"
            ></circle>
            <circle
              fill={props.dark ? '#424242' : '#FFF'}
              cx="20.1111111"
              cy="14.7777778"
              r="2.66666667"
            ></circle>
            <path
              d="M31.905768,12.2689976 C31.5391013,12.0356642 30.6946569,11.6023309 30.0168791,11.3245531 C29.3502124,11.0467753 28.4946569,10.6801087 28.1279902,10.513442 L27.4613235,10.2023309 L26.7946569,8.36899756 C26.2502124,6.88010867 26.0946569,6.54677534 25.9613235,6.61344201 C25.6724346,6.76899756 21.6724346,7.61344201 19.6835457,7.94677534 C14.5613235,8.8023309 13.405768,8.73566423 5.40576797,7.11344201 C3.72799019,6.76899756 2.15555556,6.42455312 2,6.39121979 C1.73333333,6.32455312 1.72799019,6.24677534 1.30576797,4.84677534 C0.872434635,3.39121979 0.783545746,2.94677534 0.905767968,2.94677534 C0.939101301,2.94677534 1.62799019,2.8023309 2.4391013,2.62455312 C8.06132352,1.36899756 12.6391013,0.946775341 15.2946569,1.42455312 C16.7502124,1.69121979 18.8168791,2.24677534 21.5724346,3.11344201 C23.6724346,3.76899756 26.3613235,4.5023309 26.6946569,4.5023309 C26.7613235,4.5023309 26.8613235,4.34677534 26.9168791,4.15788645 C26.9946569,3.88010867 27.1613235,3.72455312 27.7391013,3.42455312 C28.1391013,3.21344201 28.5613235,2.94677534 28.6835457,2.83566423 C29.3724346,2.22455312 30.3724346,2.21344201 32.4502124,2.78010867 C33.2279902,2.99121979 33.8835457,3.16899756 33.8946569,3.16899756 C33.9168791,3.16899756 33.6502124,3.45788645 33.305768,3.81344201 C32.9613235,4.16899756 32.3391013,4.83566423 31.905768,5.3023309 C31.2835457,5.98010867 31.0391013,6.16899756 30.705768,6.22455312 C30.1724346,6.32455312 29.1391013,6.12455312 28.105768,5.72455312 C27.6613235,5.55788645 27.2946569,5.43566423 27.2946569,5.44677534 C27.2946569,5.46899756 28.0168791,5.91344201 28.905768,6.44677534 C29.8946569,7.03566423 30.6724346,7.59121979 30.8946569,7.85788645 C31.405768,8.45788645 32.005768,9.61344201 32.2835457,10.5689976 C32.5279902,11.3912198 32.7502124,12.7356642 32.6391013,12.713442 C32.605768,12.713442 32.2724346,12.513442 31.905768,12.2689976 Z"
              fill="#EE4256"
              fillRule="nonzero"
              transform="translate(17.375753, 6.973388) scale(-1, 1) rotate(-180.000000) translate(-17.375753, -6.973388) "
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
