import styled from '@emotion/styled'

const Wrapper = styled.div`
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
  width: 100%;
  position: relative;
  padding: 10px;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 0 10px;
  }
`

export default Wrapper
