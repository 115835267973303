const website = require('./website')

module.exports = {
  // 'de-de': {
  //   path: 'de',
  //   locale: 'de-de',
  //   siteLanguage: 'de',
  //   ogLang: 'de_DE',
  // },
  'en-us': {
    default: true,
    path: 'en',
    locale: 'en-us',
    siteLanguage: 'en',
    ogLang: 'en_US',

    defaultTitle: 'Cheap eSIM mobile data and voice plans',
    defaultTitleAlt: '| Compare prices and buy',
    defaultTitleAddon: '- eSIM.Ninja',
    defaultDescription: 'Save on voice and mobile data plans while traveling the world. Chose from hundreds of eSIM plans offered by trusted operators and activate online.',
    defaultKeywords: '',
    ogMainTitle: 'Find cheap mobile data and voice plans',

    send: 'Send',
    reviewsThank: 'Thank you! Your feedback has been sent for moderation.',
    rating: 'Rating:',
    reviewsTitle: 'Reviews of eSIM by',
    reviewsTitleDevice: 'Reviews of <span property="name">${deviceName}</span> with eSIM support',
    reviewsNamePlaceholder: 'Your name',
    reviewsTextPlaceholder: 'Your feedback',
    reviewsRated:
      // eslint-disable-next-line no-template-curly-in-string
      'Rated <span property="ratingValue">${averageRating}</span> of 5 based on <span property="reviewCount">${reviewCount}</span> customer reviews',
    devices: 'Devices',
    device: 'Device',
    travel: 'travel',
    devicesBlockTitle: 'eSIM enabled devices',
    devicesBlockMoreLink: 'See all supported devices',
    faqBlockTitle: 'FAQ',
    testimonialsBlockTitle: 'Testimonials',
    title404: 'Page Not Found',
    subTitle404: "Oops, we couldn't find this page!",
    blogTitle: 'Ninja tips for staying connected while travelling',
    postOperatorTitle: 'Operators',
  },
  ru: {
    default: false,
    path: 'ru',
    locale: 'ru',
    siteLanguage: 'ru',
    ogLang: 'ru_RU',

    reviewsTitle: 'Отзывы на eSIM от',
    reviewsTitleDevice: 'Отзывы на <span property="name">${deviceName}</span> с поддержкой eSIM',
    rating: 'Рейтинг:',
    reviewsThank: 'Спасибо! Ваш отзыв отправлен на модерацию.',
    reviewsNamePlaceholder: 'Ваше имя',
    reviewsTextPlaceholder: 'Ваш отзыв',
    send: 'Отправить',
    reviewsRated:
    // eslint-disable-next-line no-template-curly-in-string
      'Рейтинг <span property="ratingValue">${averageRating}</span> из 5 на основе <span property="reviewCount">${reviewCount}</span> отзывов пользователей',
    defaultTitle: 'Выгодные eSIM тарифы на звонки и мобильный интернет',
    defaultTitleAlt: '| Сравнить и купить',
    defaultTitleAddon: '- eSIM.Ninja',
    defaultDescription: 'Экономьте на звонках и мобильном интернете во время путешествий. Выбирайте из сотен eSIM тарифов от надёжных операторов и активируйте онлайн.',
    defaultKeywords: '',
    ogMainTitle: 'Поиск выгодных тарифов на звонки и мобильный интернет',

    devices: 'Устройства',
    travel: 'путешествий',
    titleAddon: 'Сравнить и купить онлайн | eSIM.Ninja',
    devicesBlockTitle: 'Устройства с поддержкой eSIM',
    devicesBlockMoreLink: 'Посмотреть все устройств',
    faqBlockTitle: 'Ответы на частые вопросы',
    testimonialsBlockTitle: 'Отзывы клиентов eSIM.Ninja',
    title404: 'Страница не найдена',
    subTitle404: 'К сожалению, мы не смогли найти эту страницу!',
    blogTitle: 'Советы как оставаться на связи во время путешествий',
    postOperatorTitle: 'Операторы',
  },
}
