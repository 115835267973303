import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { LocaleContext } from './Layout'
import locales from '../../config/i18n'

const LocalizedLink = ({ to, ...props }) => {
  const { locale } = React.useContext(LocaleContext)
  const isIndex = to === '/'
  let path = to

  if (locales[locale]) {
    const { default: defaultLocale, path: pathLocale } = locales[locale]
    if (!defaultLocale && pathLocale) {
      path = `/${pathLocale}${isIndex ? '' : `${to}`}`
    }
  }
  if (path.slice(-1) !== '/') {
    path += '/'
  }

  return <Link {...props} to={path} />
}

export default LocalizedLink

LocalizedLink.propTypes = {
  to: PropTypes.string.isRequired,
}
