const theme = {
  colors: {
    mainColorDark: '#1C032E',
    mainColorLight: '#4a0573',
    mainColor: '#2A0643',
    secondColor: '#F5F3F6',
    black: '#424242',
    bg: '#fff',
    contrastColor: '#EE4256',
    fontLight: '#fff',
    fontDark: '#424242',
    borderTitle: '#EE4256',



    primary: '#3D63AE',
    greyLight: '#EBEDF2',
    greyBlue: '#a2bce2',
    grey: '#595C62',
    greyDark: '#303643',
    greyDarker: '#1c252b',
  },
  maxWidth: '1100px',
  maxWidthText: '720px',
  breakpoints: {
    xs: '400px',
    s: '600px',
    m: '900px',
    l: '1200px'
  },
}

export default theme
