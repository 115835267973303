// eslint-disable-next-line import/no-extraneous-dependencies
import React from 'react'

export const IconStarOff = () => (
  <svg width="100%" height="100%" viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="rate" transform="translate(-1.000000, 0.000000)" fill="#FFC108" fill-rule="nonzero">
        <path d="M416.886816,512.01601 C414.653539,512.01601 412.404412,511.562144 410.297861,510.638771 L260.494296,444.844851 L110.690731,510.638771 C105.147153,513.064582 98.6849006,512.21943 93.9649219,508.494666 C89.2291134,504.75425 87.0116888,498.713226 88.1995983,492.844362 L121.239255,329.564682 L5.74237259,215.427082 C1.51340683,211.248458 -0.038813145,205.113514 1.68763096,199.448106 C3.41406534,193.798346 8.16571325,189.54145 14.02608,188.383338 L179.57372,155.658538 L245.669252,8.7020576 C250.896074,-2.8791584 270.092745,-2.8791584 275.319567,8.7020576 L341.415099,155.658538 L506.962739,188.383338 C512.823105,189.541466 517.574744,193.798346 519.301188,199.448106 C521.027622,205.113514 519.475415,211.248458 515.246446,215.427082 L399.749564,329.564682 L432.78922,492.844362 C433.977133,498.713226 431.759696,504.75425 427.023897,508.494666 C424.09371,510.810922 420.498293,512.01601 416.887043,512.01601 L416.886816,512.01601 Z M256.99072,411.27488 C259.209469,411.27488 261.428224,411.728736 263.49072,412.652109 L389.52208,468.774029 L361.303328,327.436109 C360.256454,322.177613 361.897078,316.746957 365.678336,312.959565 L464.537856,213.940045 L322.428416,185.456493 C317.334656,184.439216 313.053408,181.011789 310.944032,176.254093 L256.990752,54.6668928 L203.037472,176.254093 C200.928096,181.011789 196.646848,184.439213 191.553088,185.456493 L49.443648,213.940045 L148.303168,312.959565 C152.084416,316.746957 153.725056,322.177613 152.678176,327.436109 L124.459424,468.774029 L250.490784,412.652109 C252.55328,411.728739 254.772032,411.27488 256.990784,411.27488 L256.99072,411.27488 Z" id="Shape"></path>
      </g>
    </g>
  </svg>
)
