import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import {StaticQuery, useStaticQuery, graphql} from 'gatsby'
import LocalizedLink from './LocalizedLink'
import {LocaleContext} from './Layout'
import Wrapper from './Wrapper'
import {IconLogo} from './Icons'

const Root = styled.footer`
  padding: 30px 0;
  flex: 0 0 auto;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    text-align: center;
  }
`

const NavFooter = styled.ul`
  margin: 0;
  list-style: none;
  li {
    display: inline-block;
    margin: 0 20px 0 0;
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      margin: 0;
    }
    a {
      color: ${props => props.theme.colors.mainColorLight};
      font-size: 16px;
      font-weight: 800;
      padding: 13px 10px;
    }
    &:first-child {
      a {
        padding: 13px 10px 13px 0;
      }
    }
  }
`

const Logo = styled.a`
  display: inline-block;
`

const Copyright = styled.div`
  display: flex;
  align-items: flex-end;
  margin: 30px 0 0 0;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    justify-content: center;
  }
  p {
    font-size: 15px;
    line-height: 15px;
    color: ${props => props.theme.colors.fontDark};
    text-align: center;
    font-weight: 600;
    margin: 0 0 0 40px;
  }
`

const Address = styled.p`
  font-size: 12px;
  line-height: 15px;
  color: #424242;
  font-weight: 600;
  margin: 10px 0 0 0;
`

const Footer = ({ links }) => {
  const lang = React.useContext(LocaleContext)
  const year = new Date().getFullYear()
  return (
    <Root>
      <Wrapper>
        <NavFooter>
          {links.map(({ path, label }) => (
            <li key={path}>
              <LocalizedLink to={`/${path}`}>{label}</LocalizedLink>
            </li>
          ))}
        </NavFooter>
        <Copyright>
          <Logo href="/">
            <IconLogo dark />
          </Logo>
          <p>© eSIM.Ninja {year}</p>
        </Copyright>
        <Address>argh.team OÜ, Sepapaja 6, Tallinn 15551, Estonia</Address>
      </Wrapper>
    </Root>
  )
}

Footer.propTypes = {
  links: PropTypes.array.isRequired,
}

export default Footer
