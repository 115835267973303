// eslint-disable-next-line import/no-extraneous-dependencies
import React from 'react'

export const IconCard = () => (
  <svg width="100%" height="100%" viewBox="0 0 144 127" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Esim-camo" transform="translate(-1223.000000, -1529.000000)" fill="#60003F"
         fillRule="nonzero">
        <g transform="translate(2.000000, 1222.000000)">
          <g transform="translate(1221.000000, 307.007463)">
            <path
              d="M138.480198,18.4333316 L49.9342464,0.146904502 C46.187997,-0.626537453 42.4748208,1.73205708 41.682245,5.38786707 L40,15.9375692 L142.167152,37.0358209 L143.849397,26.4861187 C144.641973,22.8316919 142.226448,19.2070041 138.480198,18.4333316 Z"
            ></path>
            <path
              d="M101.159317,39.6483169 C100.131786,35.9900405 96.264813,33.8276989 92.5656532,34.8419274 L75.86557,39.4253305 L36.4959582,31.1880597 L32.1661505,51.4134599 L5.1133019,58.8367491 C1.41437687,59.8512096 -0.772823788,63.6742314 0.25306462,67.3313476 L15.4989258,121.645823 C16.5264571,125.302707 20.3936645,127.466441 24.0925895,126.45082 L111.544941,102.457623 C115.243866,101.44177 117.431067,97.6201403 116.405178,93.9616319 L113.788236,84.6375054 L123.596255,86.6875417 C127.31818,87.467414 131.007248,85.0934558 131.794678,81.4138321 L138,52.4239758 L102.671041,45.0329396 L101.159317,39.6483169 Z M111.258837,67.2531515 L113.421629,57.1475285 C113.746458,55.6344231 115.26405,54.6584805 116.794316,54.9779938 L127.016117,57.1175959 C128.548026,57.4387334 129.535188,58.9374526 129.212002,60.4519503 L127.04921,70.5575733 C126.724381,72.0706787 125.207024,73.0480135 123.676523,72.727108 L113.454722,70.5888981 C111.921405,70.2666004 110.934008,68.766257 111.258837,67.2531515 Z M6.63511851,64.2568733 L30.836556,57.6192573 L87.9396499,41.9528964 L94.0844187,40.265068 C94.1966069,40.2351354 94.3085604,40.2209812 94.4179322,40.2209812 C94.9108093,40.2209812 95.5001494,40.5407266 95.6724217,41.153069 L96.3938998,43.7217053 L98.6543279,51.7742756 L8.71881499,76.448034 L5.73690874,65.8282197 C5.55008486,65.1618129 5.96128515,64.4415738 6.63511851,64.2568733 Z M110.921099,95.4645277 C111.036104,95.8766233 110.915466,96.2174839 110.793186,96.4307248 C110.672548,96.6437337 110.439723,96.9224087 110.02289,97.0358742 L22.5721811,121.029072 C22.4616358,121.059004 22.3480394,121.074551 22.2386676,121.074551 C21.7457905,121.074551 21.1564504,120.753413 20.9841781,120.141071 L12.091737,88.4602952 L102.02725,63.7851445 L107.512502,83.3244149 L110.921099,95.4645277 Z"
            ></path>
            <path
              d="M37.0206521,97.57379 C36.5951602,96.0825901 34.9981381,95.2021118 33.4682237,95.6154449 L23.1129237,98.4173883 C21.5844372,98.8307214 20.6796721,100.388723 21.105164,101.879922 L23.9784236,111.97179 C24.4039154,113.46299 26.0009375,114.343468 27.5308519,113.928743 L37.8861519,111.128191 C39.4160663,110.714858 40.3194035,109.156857 39.8953395,107.665657 L37.0206521,97.57379 Z"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)
